exports.components = {
  "component---src-layouts-books-jsx": () => import("./../../../src/layouts/books.jsx" /* webpackChunkName: "component---src-layouts-books-jsx" */),
  "component---src-layouts-chapters-jsx": () => import("./../../../src/layouts/chapters.jsx" /* webpackChunkName: "component---src-layouts-chapters-jsx" */),
  "component---src-layouts-player-jsx": () => import("./../../../src/layouts/player.jsx" /* webpackChunkName: "component---src-layouts-player-jsx" */),
  "component---src-layouts-school-jsx": () => import("./../../../src/layouts/school.jsx" /* webpackChunkName: "component---src-layouts-school-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-fellowship-application-js": () => import("./../../../src/pages/fellowship-application.js" /* webpackChunkName: "component---src-pages-fellowship-application-js" */),
  "component---src-pages-fellowship-js": () => import("./../../../src/pages/fellowship.js" /* webpackChunkName: "component---src-pages-fellowship-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-request-account-deletion-js": () => import("./../../../src/pages/request-account-deletion.js" /* webpackChunkName: "component---src-pages-request-account-deletion-js" */),
  "component---src-pages-request-data-deletion-js": () => import("./../../../src/pages/request-data-deletion.js" /* webpackChunkName: "component---src-pages-request-data-deletion-js" */),
  "component---src-pages-teachers-and-schools-js": () => import("./../../../src/pages/teachers-and-schools.js" /* webpackChunkName: "component---src-pages-teachers-and-schools-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-web-player-js": () => import("./../../../src/pages/web-player.js" /* webpackChunkName: "component---src-pages-web-player-js" */)
}

